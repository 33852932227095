<template>
  <v-app id="inspire">

    <v-navigation-drawer
        app
        right
        v-model="drawer"
    >
      <v-sheet
          color="grey lighten-4"
          :class="!miniSidebar ? 'pa-4' : 'pa-2'"
      >
        <div
            v-if="!miniSidebar"
            class="d-flex align-center justify-space-between">
          <div>
            {{ getUser.name }}
          </div>
          <!--          <v-btn-->
          <!--              @click="logoutData.modal = true"-->
          <!--              small text fab icon color="main">-->
          <!--            <v-icon :size="20">-->
          <!--              mdi-logout-->
          <!--            </v-icon>-->
          <!--          </v-btn>-->
        </div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>

        <template v-for="(menu,index) in links()">
          <v-list-group
              v-if="menu.submenus"
              :key="index"

          >
            <template v-slot:activator>
              <v-list-item-title>
                {{ menu.name }}
              </v-list-item-title>
            </template>

            <template class="ml-0" v-slot:prependIcon>
              <v-icon>
                {{ menu.icon }}
              </v-icon>
            </template>

            <template v-for="(submenu,index2) in menu.submenus">
              <v-list-item
                  exact-path
                  exact
                  :to="submenu.link"
                  :key="index2"
                  link
                  class="submenu-container"
              >
                <v-list-item-icon>
                  <v-icon small>
                    {{ submenu.icon }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-title class="text-body-2">
                  {{ submenu.name }}
                </v-list-item-title>

              </v-list-item>
            </template>

          </v-list-group>

          <v-list-item
              v-else
              link
              :to="menu.link"
              :key="index"
              exact
          >

            <v-list-item-action>
              <v-icon v-html="menu.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="menu.name"></v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </template>

        <v-list-item
            link
            @click="logoutData.modal = true"
        >

          <v-list-item-action>
            <v-icon> mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              خروج
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <v-app-bar app elevation="0">
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <v-icon>
          mdi-menu
        </v-icon>

      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-breadcrumbs large :items="getBreadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-left</v-icon>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>
      <div class="theme-switcher">
        <v-icon color="red">
          mdi-alarm-light-outline
        </v-icon>

        <v-btn text fab @click="themeSwitcher">
          <img v-if="isDarkTheme" width="30" src="/img/dark.png"/>
          <img v-else width="30" src="/img/light.png"/>
        </v-btn>

        <v-btn
            fab
            @click="$router.back()"
            icon
            circle
            color="main"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>



      </div>
    </v-app-bar>

    <v-main>
      <v-container
          class="py-8 px-6"
          fluid
      >
        <v-row no-gutters>
          <v-col cols="12">
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog max-width="500" v-model="logoutData.modal">
      <v-card>
        <v-card-title>
          خروج ؟
        </v-card-title>
        <v-card-text>
          <div>
            آیا میخواهید خارج شوید ؟
          </div>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              text
              color="main"
              @click="logoutData.modal = false"
          >
            لغو
          </v-btn>
          <v-btn
              :loading="logoutData.loading"
              color="error"
              @click="logout"
          >
            خروج
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import {logout} from "@Newfiling/module-auth/src/api";

export default {
  data: () => ({
    cards: ['Today', 'Yesterday'],
    drawer: true,
    miniSidebar: false,
    isDarkTheme: false,
    logoutData: {
      modal: false,
      loading: false,
    }
  }),

  computed: {
    getUser() {
      return this.$store.getters['User/getUser']
    },
    getBreadcrumb() {
      return this.$store.getters['Breadcrumb/getBreadcrumb']
    },
  },

  methods: {
    themeSwitcher() {
      this.isDarkTheme = !this.isDarkTheme;
      localStorage.setItem('dark', Number(this.isDarkTheme));
      this.$vuetify.theme.dark = this.isDarkTheme;
    },
    async logout() {
      this.logoutData.loading = true;
      try {
        await logout();
        this.$toast.success('با موفقیت خارج شدید.');
        await this.$router.replace('/auth/login');
      } catch (e) {
        console.log()
      }
      this.logoutData.loading = false;
    },
    links() {
      const links = [
        {
          name: 'داشبورد',
          icon: 'mdi-view-dashboard',
          link: '/panel/dashboard',
          key: "dashboard",
        },
        {
          name: 'کد های ورود',
          icon: 'mdi-format-list-bulleted',
          link: '/panel/auth/verify-code',
          key: "loginlist"
        },
        {
          name: 'پیام ها',
          icon: 'mdi-message-bulleted',
          link: '#',
          key: "message",
          submenus: [
            {
              name: 'پیامک های ارسالی',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/messages/sent-sms',
            },
            {
              name: 'پیام های ارسالی',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/messages/sent',
            },
            {
              name: 'دریافتی از کاربران',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/messages/inbox',
            },
            {
              name: 'ارسال پیام به همه',
              icon: 'mdi-message-bulleted',
              link: '/panel/messages/new-message',
            },
          ]
        },
        {
          name: 'پرداخت ها',
          icon: 'mdi-format-list-bulleted',
          link: '#',
          key: "payment",
          submenus: [

            {
              name: 'افزودن تراکنش',
              icon: 'mdi-plus-box',
              link: '/panel/payments/new',
            },
            {
              name: 'لیست تراکنش ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/payments/list',
            },

          ]
        },
        {
          name: 'مدیران',
          icon: 'mdi-account-group',
          link: '#',
          key: "management",
          submenus: [
            {
              name: 'افزودن مدیر جدید',
              icon: 'mdi-plus-box',
              link: '/panel/admins/new',
            },
            {
              name: 'لیست مدیران',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/admins/list',
            },
            {
              name: ' مجوز های دسترسی',
              icon: 'mdi-plus-box',
              link: '/panel/admins/permissions',
            },
            {
              name: 'آمار مدیران',
              icon: 'mdi-chart-bar',
              link: '/panel/admins/Statistics',
            },
          ]
        },
        {
          name: 'کاربران',
          icon: 'mdi-account-group',
          link: '#',
          key: "users",
          submenus: [
            {
              name: 'افزودن کاربر جدید',
              icon: 'mdi-plus-box',
              link: '/panel/users/new',
            },
            {
              name: 'لیست کاربران',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/users/list',
            },
            {
              name: 'کاربران انلاین',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/users/online',
            },

          ]
        },

        {
          name: 'آگهی ها',
          icon: 'mdi-account-group',
          link: '/panel/files/list',
          key: "files",
          submenus: [
            {
              name: 'ثبت آگهی جدید',
              icon: 'mdi-plus-box',
              link: '/panel/files/create',
            },
            {
              name: 'لیست آگهی های دیوار',
              icon: 'mdi-plus-box',
              link: '/panel/files/divar/list',
            },
            {
              name: 'لیست آگهی های نیوفایل',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/files/newfiling-list',
            },
            {
              name: 'لیست آگهی های کاربران',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/files/user-list',
            },
            {
              name: 'لیست آگهی های املاک',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/files/agency-list',
            },
            {
              name: 'جستجو آگهی ها',
              icon: 'mdi-magnify',
              link: '/panel/files/search',
            },
            {
              name: 'آمار آگهی ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/files/statistics',
            },

          ]
        },
        {
          name: 'مناطق',
          key: "neighborhoods",
          icon: 'mdi-format-list-bulleted',
          link: '/panel/locations/list',
        },
        {
          name: 'درخواست ها',
          icon: 'mdi-clipboard-list',
          link: '#',
          key: "requests",
          submenus: [
            {
              name: 'ثبت درخواست',
              icon: 'mdi-plus-box',
              link: '/panel/demands/create',
            },
            {
              name: 'لیست درخواست ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/demands/list',
            },

          ]
        },
        {
          name: 'آگهی های کاریابی',
          icon: 'mdi-format-list-bulleted',
          link: '/panel/jobs/list',
          key: "jobsearch",
        },
        {
          name: 'گزارشات',
          icon: 'mdi-message-bulleted',
          link: '#',
          key: "reports",
          submenus: [
            {
              name: 'گزارشات فایل ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/comments/file',
            },
            {
              name: 'گزارشات درخواست ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/comments/application',
            },
            {
              name: 'گزارشات کاریابی ها',
              icon: 'mdi-message-bulleted',
              link: '/panel/comments/job',
            },
          ]
        },
        {
          name: 'اشتراک',
          icon: 'mdi-format-list-bulleted',
          link: '#',
          key: "subscription",
          submenus: [
            {
              name: 'ایجاد مشترک جدید',
              icon: 'mdi-plus-box',
              link: '/panel/subscriptions/create',
            },
            {
              name: 'لیست اشتراک ها',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/prices/list',
            },
            {
              name: 'لیست مشترکین',
              icon: 'mdi-format-list-bulleted',
              link: '/panel/subscriptions/list',
            },

            {
              name: 'آمار مشترکین',
              icon: 'mdi-chart-bar',
              link: '/panel/subscriptions/chart',
            },

          ]
        },
      ];
      const userPermissions = this.$store.getters['User/getPermissions'].split('-');
      console.log({userPermissions})
      return links.filter(item => {
        return userPermissions.includes(item.key)
      })
    }
  },

  mounted() {
    this.isDarkTheme = Boolean(Number(localStorage.getItem('dark')) === 1) || false;
    this.$vuetify.theme.dark = this.isDarkTheme;
  },

  watch: {
    '$vuetify.theme': {
      deep: true,
      handler(value) {
        //  this.isDarkTheme = value.isDark;
      }
    }
  }
}
</script>

<style>
.v-toolbar__content {
  width: 100%;
}

.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
}


.theme--light .submenu-container {
  background-color: #eee !important;
}

.theme--dark .submenu-container {
  background-color: #00000094 !important
}
</style>